@import url("./default.less");

/* React Date Picker & Calendar Styling */

.react-month-picker,
.react-month-picker input {
  height: 100%;
  &:visited,
  &:focus,
  &:focus-visible {
    border: 1px solid @white;
  }
}

.react-month-picker .col_mp:hover,
.react-month-picker .selected_cell {
  background-color: @primary !important;
}

.react-month-picker .selected_date_mp {
  color: @primary !important;
  line-height: 28px;
  &:hover {
    color: @white !important;
  }
}

.month-input {
  text-align: center !important;
  width: 100% !important;
}

.month_name {
  position: absolute;
  right: 0;
  margin-left: 50%;
  font-size: 28px;
}

.react-month-picker {
  margin-top: auto;
  margin-bottom: auto;
}

.react-date-picker__inputGroup {
  padding: 0 0.5rem !important;
}

.react-date-picker__inputGroup__input,
.react-date-picker__inputGroup__divider,
.react-date-picker__inputGroup__leadingZero {
  color: @white;
}

div.react-date-picker__wrapper {
  border: 1;
  padding: 1px;
  background-color: @primaryDark;
  border-radius: 10px;
  font-size: 20px;
}

.react-date-picker__calendar .react-calendar {
  background-color: @white;
}

.react-date-picker {
  width: 100% !important;
  height: 100% !important;
}

.react-calendar__tile {
  color: @primary;
  font-size: 16px;
}

.react-calendar__tile--now {
  background-color: @primary;
  color: @white;
  font-size: 18px;
}

button.react-calendar__tile--active {
  background-color: @primaryDark;
  color: @white;
  &:hover {
    background-color: @primary;
    color: @white;
  }
  &:active {
    background-color: @primary;
    color: @white;
  }
}

.react-calendar__tile--range,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeBothEnds,
.react-calendar__month-view__days__day {
  color: @primary;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: @primaryDark;
}

.react-calendar__month-view__weekdays__weekday {
  color: @primary;
  font-size: 12px;
}

.react-calendar__navigation__label__labelText {
  color: @primary;
  font-size: 18px;
}

.react-calendar__tile, .react-calendar__year-view__months__month{
  padding: 20px !important;
}

button.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
  background-color: @primaryDark;
  color: @white;
}

.react-calendar__tile--now{
  background-color: @primary!important ;
  color: @white;
}
.react-calendar__tile--active{
  background-color: @primary;
  color: @white;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__next2-button {
  color: @primary;
}

.react-calendar__month-view__days__day {
  color: @primary;
}

.react-calendar__month-view__days__day:disabled,
.react-calendar__navigation__next-button:disabled,
.react-calendar__navigation__next2-button:disabled {
  color: @grey;
}

.react-calendar__month-view__days__day--weekend {
  color: @white;
  background-color: @accent;
}

.calendar-container {
  top: 25px !important;
  right: -45px !important;
  width: 175px;
}

/* .react-date-picker__inputGroup input {
  color: var(--bs-light) !important;
} */

.datePicker {
  font-size: 26px;
  color: @white;
  background-color: @primaryDark;
  border-radius: 10px;
}

.calendar-popup{
  position: absolute;
  z-index: 1000;
  top:14%;
  right: 10%;
}

.disabled-month{
  color: @grey;
}

.calendar-popup1 {
  position: absolute;
  z-index: 1000;
  top: 8%;
  right: 5%;
}

@media (max-width: 767px) {
  .react-date-picker {
    height: 131% !important;
  }
}
