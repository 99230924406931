@import url("../../assets/styles/default.css");

.expand-btn {
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  margin-left: 30px;
  transition: width 0.3s ease;
  overflow: hidden;
  border-radius: 40px !important;

  &:hover {
    width: 275px;
    border-radius: 40px;
  }
}

.expand-btn .button-text {
  opacity: 0;
}

.expand-btn:hover .button-text {
  opacity: 1;
}