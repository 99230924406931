
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.auth-container {
    min-height: 100vh;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    padding: 20px;
}

.auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 40px);
}

.auth-card {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 2.5rem;
    width: 100%;
    max-width: 450px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05);
}

.auth-header {
    text-align: center;
    margin-bottom: 2rem;
}

.auth-logo {
    width: 80px;
    margin-bottom: 1.5rem;
}

.auth-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    h1 {
        font-size: 1.75rem;
        margin: 0;
    }
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.phone-input-group {
    display: flex;
    gap: 10px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #dee2e6;
}

.country-dropdown {
    flex-shrink: 0;

    .btn {
        height: 100%;
        padding: 0.75rem;
        border: none;
        background: #f8f9fa;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.country-flag {
    width: 24px;
    height: 16px;
    object-fit: cover;
    border-radius: 2px;
}

.country-code {
    color: #6c757d;
}

.form-control-borderless {
    border: 0;
    padding: 0.75rem;
    font-size: 1rem;
}

.form-control:focus {
    box-shadow: none;
}

.otp-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.resend-section {
    text-align: center;
}

.timer {
    color: #6c757d;
    font-size: 0.9rem;
}

.resend-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.alert {
    margin: 0;
    padding: 0.75rem;
    border-radius: 10px;
}

@media (max-width: 576px) {
    .auth-card {
        padding: 1.5rem;
    }
    
    .auth-title h1 {
        font-size: 1.5rem;
    }
}