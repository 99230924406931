// ReactDateTime Calender
.react-datetime-picker__inputGroup {
  border-radius: 10px;
  padding-right: 0.5rem;
  flex-grow: 0 !important;
}

.react-datetime-picker__wrapper {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  border: thin solid #c4c0c0 !important;
  gap: 6px;
  border-right: none !important;
  border-top: none !important;
  border-left: none !important;
}

.react-datetime-picker__inputGroup > input {
  color: rgb(11, 9, 9) !important;
  background: transparent;
  border: 0;
  font-size: large;
}

.react-datetime-picker {
  width: 100%;
}

.react-datetime-picker__inputGroup__input
  react-datetime-picker__inputGroup__amPm {
  color: black !important;
  background-color: #7e4e60 !important;
}

.react-datetime-picker__inputGroup__leadingZero {
  color: black;
  font-size: large !important;
}

.react-calendar__tile--now {
  color: #fff !important;
}

.react-datetime-picker__inputGroup__divider {
  color: black;
}


//ant design
.ant-picker {
  // border: 5px solid black;
  border: 1px solid #5f5b5b;
}
.ant-picker:hover {
  border-color: #5f5b5b !important;
}
.css-dev-only-do-not-override-cdzvx5 {
  border-radius: none !important;
}
.anticon-clock-circle {
  color: #5f5b5b;
}

:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #7e4e60;
  border-color: #7e4e60;
}
:where(.css-cdzvx5).ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #7e4e60 !important;
}

.ant-picker-cell-inner:hover {
  background-color: #7e4e60 !important;
}
.ant-picker-cell-inner::before {
  border: 2px solid #7e4e60;
}
  
.ant-picker-time-panel-cell-inner:hover {
  background-color: #7e4e60 !important;
  color: white !important;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background-color: #7e4e60 !important;
  color: white !important;
}
.ant-btn {
  background-color: #7e4e60 !important;
}

.ant-btn:hover {
  background-color: #7e4e60;
}
:where(.css-dev-only-do-not-override-cdzvx5).ant-picker {
  border-radius: 0px;
}
// :where(.css-dev-only-do-not-override-cdzvx5).ant-picker .ant-picker-input >input{
//   font-weight: 400;
// }
// .ant-picker ant-picker-outlined css-dev-only-do-not-override-cdzvx5{
//   border-left: none !important;
// }
.ant-picker{
  border-left: none !important;
}
.ant-picker-input>input{
  // text-align: end;
  font-size: 1.2rem !important;
  // font-weight: 500 !important;
  color: black !important;
}
.ant-picker-input>input:focus-within{
  border-color: black;
  color: black !important;
}
.ant-picker-input>input:focus{
  box-shadow: none !important;
}


:where(.css-dev-only-do-not-override-cdzvx5).ant-picker-outlined:focus, :where(.css-dev-only-do-not-override-cdzvx5).ant-picker-outlined:focus-within {
  box-shadow: none !important;
  border-color: gray !important
}
.ant-picker-outlined{
  border-top: none !important;
  border-right: none !important;
  border-color: #808080 !important;
}
:where(.css-cdzvx5).ant-picker{
  border-radius: 0px !important;
}

:where(.css-cdzvx5).ant-picker .ant-picker-input >input:focus{
  box-shadow: none !important;
}
:where(.css-xex5fb).ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background-color: #7e4e60 !important;
}

:where(.css-dev-only-do-not-override-cdzvx5).ant-picker {
  border-radius: 0px !important;
}

:where(.css-xex5fb).ant-picker {
  border-radius: 0px !important;
}
.ant-picker{
  border-radius: 0px !important;
  box-shadow: none !important;
}
.ant-picker .ant-picker-input >input:focus{
  box-shadow: none !important;
}
:where(.css-xex5fb).ant-picker-outlined:focus, :where(.css-xex5fb).ant-picker-outlined:focus-within {
 box-shadow: none !important;
}
.ant-picker-outlined:focus-within{
  box-shadow: none !important;
}